import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion/index';
import AccordionDetails from '@mui/material/AccordionDetails/index';
import AccordionSummary from '@mui/material/AccordionSummary/index';
import Typography from '@mui/material/Typography/index';
import Button from '@mui/material/Button/index';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid/index';
import moment from 'moment';
import { nanoid } from "nanoid";
import ActionColumn from './ActionColumn';

const PreviousExecutionPanel = (props) => {

    const { classes, execution, index, buildConfig } = props;
    const [expanded, setExpanded] = useState(true);

    const handleChange = () => {
        setExpanded(!expanded)
    };

    const openReport = () => {
        let env = process.env.NODE_ENV;
        let host;
        if (env === "local") {
            host = "https://dev.hod.performance.nikecloud.com/reports/";
        } else if ((env === "prod") || (env === "production")) {
            host = "https://hod.performance.nikecloud.com/reports/";
        } else {
            host = "https://" + env + ".hod.performance.nikecloud.com/reports/"
        }
        console.log("opening new window at... ", host + execution.HiveCLI_reportPath.S);
        window.open(host + execution.HiveCLI_reportPath.S);
    };

    const copyBuildNumber = (buildNumber) => (evt) => {
        console.log(buildNumber)
        navigator.clipboard.writeText(buildNumber).then(() => console.log(buildNumber + " Copied"));
    };

    const openWorkspace = () => {
        let env = process.env.NODE_ENV;
        let host;
        if (env === "local") {
            host = "https://dev.hod.performance.nikecloud.com/reports/";
        } else if ((env === "prod") || (env === "production")) {
            host = "https://hod.performance.nikecloud.com/reports/";
        } else {
            host = "https://" + env + ".hod.performance.nikecloud.com/reports/"
        }
        let zipFile = execution.HOD_s3WorkspacePath.S + "/workspace.zip";
        console.log("opening new window at... ", host + zipFile);
        window.open(host + zipFile);
    };

    const openHodLog = () => {
        let env = process.env.NODE_ENV;
        let host;
        if (env === "local") {
            host = "https://dev.hod.performance.nikecloud.com/reports/";
        } else if ((env === "prod") || (env === "production")) {
            host = "https://hod.performance.nikecloud.com/reports/";
        } else {
            host = "https://" + env + ".hod.performance.nikecloud.com/reports/"
        }
        let logFile = execution.HOD_logPath.S;
        console.log("opening new window at... ", host + logFile);
        window.open(host + logFile);
    };

    const openHiveCliLog = () => {
        let env = process.env.NODE_ENV;
        let host;
        if (env === "local") {
            host = "https://dev.hod.performance.nikecloud.com/reports/";
        } else if ((env === "prod") || (env === "production")) {
            host = "https://hod.performance.nikecloud.com/reports/";
        } else {
            host = "https://" + env + ".hod.performance.nikecloud.com/reports/"
        }
        let zipFile = execution.HOD_s3WorkspacePath.S + "/HiveCLI_run.log";
        console.log("opening new window at... ", host + zipFile);
        window.open(host + zipFile);
    };

    const openBeeLog = () => {
        let env = process.env.NODE_ENV;
        let host;
        if (env === "local") {
            host = "https://dev.hod.performance.nikecloud.com/reports/";
        } else if ((env === "prod") || (env === "production")) {
            host = "https://hod.performance.nikecloud.com/reports/";
        } else {
            host = "https://" + env + ".hod.performance.nikecloud.com/reports/"
        }
        console.log("opening new window at... ", host + execution.HiveCLI_beeLogPath.S);
        window.open(host + execution.HiveCLI_beeLogPath.S);
    };

    return (
        <Accordion expanded={expanded} id={('executionPanel' + index)}
            onChange={() => handleChange()}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Typography >{`Service: ${execution.pltConfig.service}`}</Typography>
                    <Typography>{`Sim: ${execution.pltConfig.HiveOnDemand.simulation.name}`}</Typography>
                    <Typography >{moment(execution.date, "YYYY MM DD hh:mm:ss").format("MM-DD-YYYY hh:mm:ss")}</Typography>
                    <Typography >Build Number: {execution.pltConfig.buildNumber}</Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid
                    container
                    direction={"row"}
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                    sx={{
                        marginBottom: 3
                    }}
                >
                    <Grid item>
                        <Typography >{`User: ${buildConfig.userEmail}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{`Beekeeper: ${buildConfig.beekeeperUrl}`}</Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction={"row"}
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                    sx={{
                        marginBottom: 3
                    }}
                >
            
                    <Grid item>
                        <Typography >{`Gatling: ${buildConfig.HiveOnDemand.simulation.gatlingVersion}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{`Scenario: ${buildConfig.HiveOnDemand.simulation.selectedScenario}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{`LeadInjector: ${buildConfig.HiveOnDemand.simulation.selectedLoadInjector}`}</Typography>
                    </Grid>
                </Grid>

                {
                    Object.entries(buildConfig.HiveOnDemand.targetGroups).map(([key, value]) => {
                        return (
                            <Grid
                                container
                                direction={"row"}
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={3}
                                sx={{
                                    marginBottom: 3
                                }}
                                key={`tg-${key}`}
                            >
                                <Grid item>
                                    <Typography >{`TargetGroup: ${key}`}</Typography>
                                </Grid>

                                <Grid
                                    container
                                    direction={"column"}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={3}
                                    sx={{
                                        marginBottom: 3,
                                    }}
                                    key={`tg-hosts-${key}`}
                                >
                                    {
                                        value.map((tg, index) => {
                                            return (
                                                <Grid item key={`host-${index}`}>
                                                    <Typography >{`${tg.data.hostName}`}</Typography>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }

                <Grid item>
                    <Typography >{`Endpoints:`}</Typography>
                </Grid>

                {
                    Object.entries(buildConfig.HiveOnDemand.scenarios.filter(scenario => scenario.label === buildConfig.HiveOnDemand.simulation.selectedScenario)[0].data.columns).map(([col, colData]) => {
                        return (
                            <Grid
                                container
                                direction={"column"}
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={3}
                                sx={{
                                    marginBottom: 3
                                }}
                                key={`grid-${col}`}
                            >
                                {
                                    <Grid item>
                                        <ActionColumn
                                            key={col}
                                            column={colData}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        )
                    })
                }

                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item>
                        <Button
                            id="openReportButton"
                            // className={classes.resultButton}
                            color="primary"
                            onClick={openReport}
                            disabled={!execution.HiveCLI_reportPath || !execution.HiveCLI_reportPath.S}
                        >
                            Report
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            // className={classes.resultButton}
                            color="primary"
                            onClick={copyBuildNumber(execution.pltConfig.buildNumber)}
                        >
                            Copy Build Number
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            id='prevExecutionHodLog'
                            // className={classes.resultButton}
                            color="primary"
                            onClick={openHodLog}
                            disabled={!execution.HOD_uploadHODLog || !(execution.HOD_uploadHODLog.M.complete && (execution.HOD_logPath.S !== undefined))}
                        >
                            HOD Log
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            id='prevExecutionHiveCliLog'
                            // className={classes.resultButton}
                            color="primary"
                            onClick={openHiveCliLog}
                            disabled={!execution.HOD_uploadWorkspace || !execution.HOD_uploadWorkspace.M.complete}
                        >
                            HiveCLI Log
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            id='prevExecutionBeeLog'
                            // className={classes.resultButton}
                            color="primary"
                            onClick={openBeeLog}
                            disabled={!execution.HiveCLI_beeLogPath || execution.HiveCLI_beeLogPath.S === undefined}
                        >
                            Bee Log
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            id='prevExecutionWorkspace'
                            // className={classes.resultButton}
                            color="primary"
                            onClick={openWorkspace}
                            disabled={!execution.HOD_uploadWorkspace || !execution.HOD_uploadWorkspace.M.complete}
                        >
                            Workspace
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

export default PreviousExecutionPanel;
