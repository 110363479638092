import React from "react";
import SearchComponent from "./SearchComponent";

const MainComponentContainer = (props) => {

    return (
        <div className={"hive"}>
            <SearchComponent></SearchComponent>
        </div>
    );

}

export default MainComponentContainer;
