import React from "react";
import './assets/css/hive.css';

import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import MainComponentContainer from "./components/MainComponentContainer";

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5'
        },
        text: {
            primary: '#000'
        }
    },
    typography: {
        button: {
            //      fontStyle: 'italic',
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: "standard", // Non-outlined variant for text input fields
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",
            }
        }
    }
});

const App = () => {

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <br />
                <h3 align="center" className="headline-md-brand">
                    Hive On-Demand Admin
                </h3>
                <br />
                <MainComponentContainer />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;

