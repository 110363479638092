
export function getConfig(buildNumber) {

    let env = process.env.NODE_ENV;
    let hodAPI;
    //TODO: move the hodControllerPort and hodControllerEnv properties to a .env file
    if (env === "local") {
        let hodControllerPort = 3010;
        let hodControllerEnv = "local";
        hodAPI = "http://localhost:" + hodControllerPort + "/" + hodControllerEnv + "/";
    } else if ((env === "prod") || (env === "production")) {
        hodAPI = "https://hod.api.performance.nikecloud.com/"
    } else {
        hodAPI = "https://" + env + ".hod.api.performance.nikecloud.com/"
    }

    return new Promise((resolve, reject) => {
        let endpoint = `getConfig/${buildNumber}`;
        console.log("HodController: getConfig - calling hod_controller at: ", hodAPI + endpoint);
        
        fetch(hodAPI + endpoint, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }).then((hodControllerResponse) => {
            if (!((hodControllerResponse.status === 201) || (hodControllerResponse.status === 200))) {
                let errorMSG = "callHodController fetch FAILURE: " + hodControllerResponse.status;
                reject(JSON.stringify(errorMSG))
            } else {
                console.log("HodController: getConfig - complete: ", hodControllerResponse)
                hodControllerResponse.json().then((jsonResponse) => {
                    console.log("HodController: getConfig jsonResponse - complete: ", jsonResponse)
                    resolve(jsonResponse)
                })
            }
        }).catch((e) => {
            console.log("HodController: getConfig FAILURE - ", e)
        });

    })
}

export function getPastExecutions(itemName, itemType, buildNumber) {
    let env = process.env.NODE_ENV;
    let hodAPI;
    //TODO: move the hodControllerPort and hodControllerEnv properties to a .env file
    if (env === "local") {
        let hodControllerPort = 3010;
        let hodControllerEnv = "local";
        hodAPI = "http://localhost:" + hodControllerPort + "/" + hodControllerEnv + "/";
    } else if ((env === "prod") || (env === "production")) {
        hodAPI = "https://hod.api.performance.nikecloud.com/"
    } else {
        hodAPI = "https://" + env + ".hod.api.performance.nikecloud.com/"
    }

    const body = {
        itemName,
        itemType,
        userEmail: buildNumber,
        scanForward: false
    };

    return new Promise((resolve, reject) => {
        let endpoint = "getPastExecutions";
        console.log("HodController: getPastExecutions - calling hod_controller at: ", hodAPI + endpoint);
        fetch(hodAPI + endpoint, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' }
        }).then((hodControllerResponse) => {
            if (!((hodControllerResponse.status === 201) || (hodControllerResponse.status === 200))) {
                let errorMSG = "callHodController fetch FAILURE: " + hodControllerResponse.status;
                reject(JSON.stringify(errorMSG))
            } else {
                console.log("HodController: getPastExecutions - complete: ", hodControllerResponse)
                hodControllerResponse.json().then((jsonResponse) => {
                    console.log("HodController: getPastExecutions jsonResponse - complete: ", jsonResponse)
                    resolve(jsonResponse)
                })
            }
        }).catch((e) => {
            console.log("HodController: getPastExecutions FAILURE - ", e)
        });

    })
}


