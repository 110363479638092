import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import * as HodController from '../util/HodController';
import ExecutionPanel from "./ExecutionPanel";
import { margin } from "@mui/system";

const SearchComponent = (props) => {

    const { classes } = props;
    const [buildNumber, setBuildNumber] = useState('');
    const [buildConfigs, setBuildConfigs] = useState([]);
    const [executions, setExecutions] = useState([]);

    const searchBuildNumber = () => {
        HodController.getConfig(buildNumber).then(config => {
            setBuildConfigs([...buildConfigs, config]);
        }).catch(err => {
            console.log(err);
        });

        HodController.getPastExecutions('jobId', 'S', buildNumber).then((hodControllerResponse) => {
            console.log("PreviousExecution: hodControllerResponse - ", hodControllerResponse)
            setExecutions([...executions, ...hodControllerResponse.items]);
        }).catch((err) => {
            console.log(err);
            let errorMessage = "An error occurred while calling the HOD controller. If you are running HOD locally, " +
                "you may need to refresh your aws creds and restart the HOD_Controller. " +
                "Otherwise please contact someone in #cop-performance and let them know.";
        });

    }

    return (
        <div>
            <Grid container direction="row" spacing={3} justifyContent={"center"} alignItems={"center"} sx={{
                margin: 3,
                alignSelf: "center",
            }}>
                <Grid item sx={{
                    minWidth: "50%"
                }}>
                    <TextField
                        id="buildNumberInput"
                        label="Build Number"
                        value={buildNumber}
                        onChange={e => setBuildNumber(e.target.value)}
                        margin="normal"
                        sx={{
                            width: "100%"
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        id="searchBtn"
                        color="primary"
                        onClick={searchBuildNumber}
                    >Search</Button>
                </Grid>
            </Grid>

            <Grid container direction="column" spacing={3} justifyContent={"center"} alignItems={"center"} sx={{
                alignSelf: "center",
            }}>
                {executions.map((execution, index) => {
                    return (
                        <Grid item key={('panel' + index)} sx={{
                            width: "80%"
                        }}>
                            <ExecutionPanel
                                execution={execution}
                                buildConfig={buildConfigs[index]}
                                index={index}
                                sx={{
                                    width: "80%"
                                }}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    );

}

export default SearchComponent;
