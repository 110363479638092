// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hive {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}

.hive h3 {
    font-size: 30px;
    line-height: .9;
    font-family: "Nike TG","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 400;
    letter-spacing: -1px;
}

.arrange-horizontally > * {
    display: inline-block !important;
    text-align: center;
    margin: 0 1rem 0 0;
}
.arrange-vertically > * {
    display: block;
}

.import-simulation p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #8d8d8d;
    font-size: 16px;
    line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/hive.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,kEAAkE;IAClE,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,2DAA2D;IAC3D,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".hive {\n    width: 80%;\n    margin: 0 auto;\n    padding: 20px;\n}\n\n.hive h3 {\n    font-size: 30px;\n    line-height: .9;\n    font-family: \"Nike TG\",\"Helvetica Neue\",Helvetica,Arial,sans-serif;\n    font-weight: 400;\n    letter-spacing: -1px;\n}\n\n.arrange-horizontally > * {\n    display: inline-block !important;\n    text-align: center;\n    margin: 0 1rem 0 0;\n}\n.arrange-vertically > * {\n    display: block;\n}\n\n.import-simulation p {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: #8d8d8d;\n    font-size: 16px;\n    line-height: 24px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
