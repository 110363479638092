import React from 'react';
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion/index';
import AccordionDetails from '@mui/material/AccordionDetails/index';
import AccordionSummary from '@mui/material/AccordionSummary/index';
import Typography from '@mui/material/Typography/index';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const doNotList = ["id", "label", "requestBuilderExpanded", "responseBuilderExpanded", "requestName"];

class ActionColumn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = () => {
        console.log("change")
        this.setState({expanded: !this.state.expanded});
    };

    render() {

        //console.log("ActionColumn props:", this.props);
        const { classes } = this.props;

        return (
            <Accordion expanded={this.state.expanded} id={('executionPanel')}
                onChange={() => this.handleChange()}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                        <Typography >{`Endpoint: ${this.props.column.label}`}</Typography>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container
                        direction={"column"}
                    >
                        <Grid item
                        >
                            {this.props.column.label}
                        </Grid>
                        <Grid container direction={"column"}>
                            {
                                Object.entries(this.props.column).map(([key, value]) => {
                                    //console.log("columnDataKey: ", key);
                                    //console.log("columnDataValue: ", value);
                                    if (doNotList.includes(key)) {
                                        return null;
                                    } else if (key === "headers") {
                                        return <Grid item key={key}>{key} :
                                            {
                                                Object.values(value).map(header => {
                                                    //console.log("header: ", header);
                                                    let stringifiedHeader = JSON.stringify(header, null, 2);
                                                    //console.log("StringifiedHeader: ", stringifiedHeader);
                                                    return <Grid item
                                                        key={stringifiedHeader}>
                                                        {stringifiedHeader}
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    } else if (key === "responseActions") {
                                        return <Grid item key={key}>{key} :
                                            {
                                                value.map((responseAction, index) => {
                                                    return <Grid item
                                                        key={"responseAction" + index}>
                                                        {responseAction.expression}
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    } else if (key === "requestAuth") {
                                        return <Grid item key={key}>{key} :
                                            {
                                                Object.entries(value).map(requestAuth => {
                                                    // let stringifiedRequestAuth = JSON.stringify(requestAuth, null, 2);
                                                    return <Grid item
                                                        key={requestAuth[0]}>
                                                        {`${requestAuth[0]} : ${requestAuth[1]}`}
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    } else {
                                        return <Grid item key={key}>
                                            {key} : {value}
                                        </Grid>
                                    }
                                })
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    }
}

export default ActionColumn